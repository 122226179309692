





















































import { Component, Prop, Vue, Provide } from 'vue-property-decorator';
import CreateApiKeyForm from './components/CreateApiKeyForm.vue';
import CoolapseCard from '@/components/shared/CoolapseCard.vue';
import { listApiKeys, deleteApiKey } from '@/apis/apiKey.api';
import { myLocation } from '@/apis/system.api';
import { Action, Getter, State } from 'vuex-class';
import { AccountDetail } from '@/dtos/Account';
import {
  mounted,
  errorPopupHandler,
  errorCatchHandler,
  loadings,
} from '@/utils/decorators/VueTimmer';
import { from } from 'rxjs';
import CopyAble from '@/components/shared/CopyAble.vue';

@Component({
  components: {
    CreateApiKeyForm,
    CoolapseCard,
    CopyAble,
  },
})
export default class APIKeysPage extends Vue {
  @State(e => (e.account && e.account.detail) || null)
  account!: AccountDetail;

  myLocation: any = null;

  loadings = {
    creating: false,
    loadApiKey: false,
    removing: {},
  };

  pageing = {
    canNext: false,
    canPrev: false,
  };

  showApiKeys = [];

  showForm = false;
  apiKeys: any[] = [];

  enable() {
    if (!this.account.enable_2fa) {
      this.$popupError({
        data: 'Please Enable Two-Factor Authentication First',
      });
      return;
    }
  }

  hideForm() {
    this.showForm = false;
  }

  @mounted
  getMyLocation() {
    myLocation().then(data => {
      this.myLocation = data;
    });
  }

  @mounted
  @loadings('loadings.loadApiKey')
  listApiKeys() {
    if (!this.account.enable_2fa) {
      this.loadings.loadApiKey = false;
      return [];
    }
    return listApiKeys().then(keys => {
      console.log(keys);
      this.apiKeys = keys;
    });
  }

  onCreateSuccess() {
    this.hideForm();
    this.listApiKeys();
    this.$popupSuccess(
      'We sent you an email with activation link, please click the activation link to activate your api key.',
    );
  }

  @loadings(id => 'loadings.removing.' + id)
  removeApiKey(id, label) {
    return deleteApiKey(id).finally(() => {
      this.listApiKeys();
    });
  }

  showApiKey(apiKey) {
    if (apiKey.status != 'enable') {
      this.$popupError({
        data: 'Please Activate Your Api Key First',
      });
    } else {
      const index = this.showApiKeys.indexOf(apiKey.id);
      if (index > -1) {
        this.showApiKeys.splice(index, 1);
      } else {
        this.showApiKeys.push(apiKey.id);
      }
    }
  }
}
