





































import { Component, Mixins, Vue } from 'vue-property-decorator';
import { listRoles, createApiKey } from '@/apis/apiKey.api';
import {
  mounted,
  errorPopupHandler,
  errorCatchHandler,
  loadings,
} from '@/utils/decorators/VueTimmer';
import FormMixin from '@/mixins/form';
import { Message } from 'element-ui';

@Component({
  props: {
    myIp: {
      type: String,
      default: '',
    },
  }
})
export default class CreateApiKeyForm extends Mixins(FormMixin) {
  myIp: string;
  roles: any[] = [];
  loadings = {
    submitting: false,
  };

  errors = {
    submitting: '',
  };

  form = {
    label: '',
    fa_code: '',
    api_role: '',
    restrict_ip: true,
    restrict_ips: '',
  };

  @mounted
  listRoles() {
    listRoles().then(data => {
      this.roles = data;
    });
  }

  @errorCatchHandler('errors.submitting', '', 'form')
  @loadings('loadings.submitting')
  submit() {
    return createApiKey(this.form)
      .then(() => {
        this.$emit('success');
      })
      .finally(() => {
        this.form.fa_code = '';
        this.clearVeeErros();
      });
  }
}
