import { http } from './http';

export function createApiKey(form: { label; api_role; fa_code }) {
  return http
    .callApi({
      resource: 'apiKey.create',
      data: {
        body: form,
      },
    })
    .then(e => e.data);
}

export function listRoles() {
  return http
    .callApi({
      resource: 'apiKey.listRoles',
    })
    .then(e => e.data);
}

export function listApiKeys() {
  return http
    .callApi({
      resource: 'apiKey.list',
    })
    .then(e => e.data);
}

export function deleteApiKey(id: string) {
  return http
    .callApi({
      resource: 'apiKey.delete',
      data: {
        body: {
          id : id
        }
      }
    })
    .then(e => e.data);
}
